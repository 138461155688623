<template>
  <div>
    <VList
      :hide-header="true"
      :rows="displayedRows"
      :headers="headers"
      display-pagination
      :is-loading="isLoading"
      :pagination="pagination"
      @update:current_page="onUpdateCurrentPage"
      @update:per_page="onUpdatePerPage"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage :name="item.name" :color="item.colour" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.name="{ item }">
        <div class="cursor-pointer" @click="onClickUpdate(item.id)">
          <div class="font-medium whitespace-nowrap">
            {{ item.name }}
          </div>
          <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
            {{ item.displayed_details }}
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="item.displayed_status"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.__ACTIONS="{ item }">
        <div class="flex justify-center">
          <VAction icon="edit" @click="onClickUpdate(item.id)" />

          <VDeleteAction
            :item="item"
            :text-value="item.name"
            :delete-function="onClickDelete"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRead from "@/composables/useRead";
import useDepartment from "@/composables/useDepartment";
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
// Components
import VList from "@/components/tables/VList";
import VAction from "@/components/tables/VAction";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VDeleteAction from "@/components/VDeleteAction";

export default {
  components: {
    VDeleteAction,
    VList,
    VAction,
    VImage,
    VChip
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // COMPUTED
    const documentTitle = computed(() => {
      return `${t("app.departments")} - ${t("app.administration")}`;
    });

    const displayedRows = computed(() => {
      return rows.value.map(row => ({
        ...row,
        displayed_details: trimHTML(row.details),
        displayed_status: row.status ? t(`app.${row.status}`) : ""
      }));
    });

    // CUSTOM COMPOSABLES
    const { trimHTML } = useDisplay();
    const { getStatusColor } = useColor();
    const { endpoint, route, types, searchFields, headers } = useDepartment();
    const {
      pagination,
      rows,
      isLoading,
      onClickUpdate,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete
    } = useRead({
      endpoint,
      route,
      types,
      searchFields
    });

    return {
      documentTitle,
      displayedRows,
      // useDepartment
      headers,
      // useColor
      getStatusColor,
      // useRead
      pagination,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      rows,
      isLoading,
      onClickUpdate
    };
  }
};
</script>
