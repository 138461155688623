import { ref, computed, readonly, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import useDownload from "@/composables/useDownload";

export default function useSearch(args) {
  // MISC
  const { t } = useI18n();
  const store = useStore();
  const router = useRouter();
  const _ = inject("lodash");

  // DATA
  const { types = {}, searchFields, route, endpoint, addedFilters = [] } = args;
  const isSearching = ref(false);
  const filters = ref([]);

  // COMPUTED
  const fieldOptions = computed(() => {
    return Object.keys(types).map(key => ({
      text: t(`app.${key}`),
      value: key
    }));
  });

  // METHODS
  const onClickSearch = filters => {
    isSearching.value = true;

    search(filters);

    isSearching.value = false;
  };

  const encodeFilters = filters => {
    return encodeURIComponent(JSON.stringify(filters));
  };

  const decode = filters => {
    return JSON.parse(decodeURIComponent(filters));
  };

  const search = updatedFilters => {
    filters.value = encodeFilters(updatedFilters);
    store.dispatch("queries/setFilters", filters);
    router.replace({ name: route });
  };

  const onUpdateSearch = _.debounce(v => {
    const filters = [
      {
        f: searchFields,
        c: "LIKE",
        v: Array.isArray(v) ? v : [v]
      }
    ];

    search(filters);
  }, 500);

  const onClickExport = () => {
    const { download } = useDownload();

    const ro_f = encodeFilters([...filters.value, ...addedFilters]);

    download(endpoint, {}, { ro_f });
  };

  return {
    onClickSearch,
    fieldOptions: readonly(fieldOptions),
    onUpdateSearch,
    isSearching,
    filters,
    onClickExport,
    encodeFilters,
    decode
  };
}
