import { computed, watch, onMounted } from "vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import usePaginate from "@/composables/usePaginate";
import useSearch from "@/composables/useSearch";
import useDelete from "@/composables/useDelete";

/**
 * @param {Object} args
 *  @param {String} endpoint - required - used to get the data
 *  @param {String} route - optional - needed for navigation functionalities
 *  @param {Array<String>} relations - optional - used with the data
 *  @param {Object} types - optional - needed for search functionalities
 *  @param {Array<String>} searchFields - optional - needed for search functionalities
 * @returns {Object}
 */
export default function useRead(args) {
  const {
    endpoint,
    route,
    relations = [],
    types,
    searchFields = [],
    addedFilters = []
  } = args;

  // MISC
  const store = useStore();
  const router = useRouter();
  const currentRoute = useRoute();

  const { isLoading, data, read, pagination } = useReadMultiple();
  const { filters, encodeFilters, decode } = useSearch({
    types,
    searchFields,
    route
  });

  // COMPUTED
  const queries = computed(() => store.getters["queries/queries"]);

  const allFilters = computed(() => {
    if (addedFilters.length === 0) {
      return filters.value;
    }

    return encodeFilters([
      ...addedFilters,
      ...(filters.value.length > 0 ? decode(filters.value) : [])
    ]);
  });

  // LIFECYCLE HOOKS
  onMounted(() => {
    store.dispatch("queries/setRoute", route);
  });

  // METHODS
  const getData = async () => {
    await read({
      endpoint,
      queryParams: {
        ro_f: allFilters.value,
        ro_r: relations
      }
    });
  };

  const onClickUpdate = id => {
    router.push({
      name: `${route}-update`,
      params: { id }
    });
  };

  const onClickUpdateDetails = id => {
    router.push({
      name: `${route}-details`,
      params: {
        id
      }
    });
  };

  // WATCH
  watch(
    () => queries,
    async () => {
      // @NOTE: this is to prevent a 403 redirection when impersonating
      const hasRoute = currentRoute.matched.some(r => {
        return r.name === route || r.meta.parentName === route;
      });

      if (!hasRoute) {
        return;
      }

      const { filters: queryFilters, per_page, current_page } = queries.value;

      if (queryFilters.length > 0) {
        filters.value = queryFilters;
      }

      if (current_page) {
        pagination.value.current_page = current_page;
      }

      if (per_page) {
        pagination.value.per_page = per_page;
      }

      await getData();
    },
    {
      deep: true,
      immediate: true
    }
  );

  const { onClickDelete } = useDelete({ endpoint, getData });
  const { onUpdateCurrentPage, onUpdatePerPage } = usePaginate(pagination);

  return {
    onClickUpdate,
    onClickUpdateDetails,
    // useReadMultiple
    getData,
    rows: data,
    isLoading,
    pagination,
    // usePaginate
    onUpdatePerPage,
    onUpdateCurrentPage,
    // useDelete
    onClickDelete
  };
}
